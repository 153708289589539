import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)


export default new Router({
    mode: 'history',
    // base: 'http://localhost:8080/',
    // base: process.env.BASE_URL,
    routes: [
      {
        path: '/',
        component: () => import('@/components/Index'),
        redirect: '/admin/login',
        children: [
          {
            name: 'LoginAdmin',
            path: '/admin/login',
            component: () => import('@/components/pages/admin/Login'),
          },
          {
            name: 'Devices',
            path: '/admin/devices',
            component: () => import('@/components/pages/admin/Devices'),
          },
          {
            name: 'ResetPassword',
            path: '/admin/reset_password',
            component: () => import('@/components/pages/admin/ResetPassword'),
          },
          {
            name: 'Logout',
            path: '/admin/logout',
            component: () => import('@/components/pages/admin/Logout'),
          },
          {
            name: 'Surveys',
            path: '/admin/surveys',
            component: () => import('@/components/pages/admin/Surveys'),
          },
          {
            name: 'UpdatePassword',
            path: '/update_password',
            component: () => import('@/components/pages/admin/UpdatePassword'),
          },
          {
            name: 'LoginUser',
            path: '/user/login',
            component: () => import('@/components/pages/user/Login'),
          },
          {
            name: 'Quiz',
            path: '/user/quiz',
            component: () => import('@/components/pages/user/Quiz'),
          },
          {
            name: 'LoginSuper',
            path: '/super/login',
            component: () => import('@/components/pages/super/Login'),
          },
          {
            name: 'DashboardSuper',
            path: '/super/dashboard',
            component: () => import('@/components/pages/super/Dashboard'),
          },
          {
            path: '/admin',
            component: () => import('@/components/pages/admin/Login'),
            redirect : "/admin/login",
          },
          {
            path: '/super',
            component: () => import('@/components/pages/super/Login'),
            redirect : "/super/login",
          },
          {
            path: '/user',
            component: () => import('@/components/pages/user/Login'),
            redirect : "/user/login",
          },
          {
            path: '/tablet',
            component: () => import('@/components/pages/user/Login'),
            redirect : "/user/login",
          },
        ],
      },
    ],
  })
  