import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueToast from 'vue-toast-notification';
import Vuelidate from 'vuelidate'
import i18n from './i18n'
import ToggleButton from 'vue-js-toggle-button'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCountdown from '@chenfengyuan/vue-countdown';
// eslint-disable-next-line no-unused-vars
import jwt_decode from "jwt-decode";

axios.defaults.timeout = 5000;
Vue.use(VueAxios, axios);
Vue.component(VueCountdown.name, VueCountdown);


Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VueToast);
Vue.use(ToggleButton)
// Vue.use(jwt_decode);

Vue.config.productionTip = false
axios.defaults.timeout = 5000;

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
